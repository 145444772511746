import React, { useState, useEffect } from "react";
import AdminLayout from "../components/AdminLayout";
import { Table, Space, Row, Col, Spin, Input } from "antd";
import {
  DeleteOutlined,
  StopOutlined,
  MobileOutlined,
  LockOutlined,
} from "@ant-design/icons";
import ImportCsvModal from "../components/ImportCsvModal";
import AddSubscriberModal from "../components/AddSubscriberModal";
import { getPaginatedUsers, getUsersCount } from "../api/users";
import SCButton from "../components/SCButton";
import { navigate } from "gatsby";
import Swal from "sweetalert2";
import { serverUri } from "../api/serverUri";
import moment from "moment-timezone"; // Importa moment-timezone

const { Search } = Input;

const Suscriptores = () => {
  // Estados de paginación
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalUsers, setTotalUsers] = useState(0);

  // Estado para los datos (arreglo de usuarios devuelto por el endpoint filtrado)
  const [usersData, setUsersData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [stateModal, setStateModal] = useState(false);
  const [addSubModal, setAddSubModal] = useState(false);


  const showImportModal = () => setStateModal(true);
  const hideImportModal = () => setStateModal(false);
  const showAddSubscriberModal = () => setAddSubModal(true);
  const hideAddSubscriberModal = () => setAddSubModal(false);

  // Función para obtener usuarios pasando el searchTerm al endpoint
  const fetchUsers = (page = currentPage, size = pageSize, term = searchTerm) => {
    setLoading(true);
    Promise.all([getPaginatedUsers(page, size, term), getUsersCount(term)])
      .then(([paginatedResponse, count]) => {
        setUsersData(paginatedResponse.data);
        setTotalUsers(count);
      })
      .catch((error) => {
        console.error("Error al obtener usuarios:", error);
        Swal.fire("Error", "No se pudieron obtener los usuarios", "error");
      })
      .finally(() => setLoading(false));
  };

  // Llama a fetchUsers cuando cambie currentPage, pageSize o searchTerm
  useEffect(() => {
    fetchUsers(currentPage, pageSize, searchTerm);
  }, [currentPage, pageSize, searchTerm]);

  // Reinicia la página actual a 1 cuando el searchTerm cambia
  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  const showUser = (email) => {
    navigate(`/suscriptores/${email}`);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => setSelectedRowKeys(newSelectedRowKeys),
  };

  // Configuración de la paginación de Ant Design
  const paginationConfig = {
    current: currentPage,
    pageSize: pageSize,
    total: totalUsers,
    onChange: (page, pageSize) => {
      setCurrentPage(page);
      setPageSize(pageSize);
    },
  };

  // Función para exportar a CSV usando la data filtrada (ya retornada por el backend)
  const exportCSV = () => {
    const dataToExport =
      selectedRowKeys.length > 0
        ? usersData.filter((user) => selectedRowKeys.includes(user.email))
        : usersData;
    if (!dataToExport || dataToExport.length === 0) {
      Swal.fire("Atención", "No hay datos para exportar", "warning");
      return;
    }
    const headers = ["Nombre", "Apellido", "Email", "Membresía", "Fecha de suscripción"];
    const csvRows = [];
    csvRows.push(headers.join(";"));
    dataToExport.forEach((user) => {
      const row = [
        user.nombre,
        user.apellido,
        user.email,
        user.suscription,
        // Aquí se formatea la fecha a UTC-6
        moment(user.createdAt)
          .tz("America/Mexico_City")
          .format("YYYY-MM-DD HH:mm:ss"),
      ];
      csvRows.push(row.join(";"));
    });
    const csvString = csvRows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "usuarios.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Implementación de handleDelete: solicita confirmación y realiza la petición DELETE
  const handleDelete = (record) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Esta acción eliminará al usuario. No se podrá deshacer.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        const authToken = localStorage.getItem("token"); // Obtén el token desde localStorage
        fetch(`${serverUri}/content-manager/collection-types/plugins::users-permissions.user/${record.id}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${authToken}`,
          },
        })
          .then((response) => {
            if (response.ok) {
              Swal.fire("Eliminado", "El usuario ha sido eliminado.", "success");
              fetchUsers(); // Actualiza la lista después de eliminar
            } else {
              Swal.fire("Error", "No se pudo eliminar el usuario.", "error");
            }
          })
          .catch((error) => {
            console.error("Error al eliminar usuario:", error);
            Swal.fire("Error", "No se pudo eliminar el usuario.", "error");
          });
      }
    });
  };

  const columns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
    },
    {
      title: "Apellido",
      dataIndex: "apellido",
      key: "apellido",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Membresía",
      dataIndex: "suscription",
      key: "suscription",
    },
    {
      title: "Fecha de suscripción",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) =>
        moment(date)
          .tz("America/Mexico_City")
          .format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "Acción",
      key: "acción",
      render: (text, record) => (
        <div style={{ paddingLeft: "5px", height: "100%" }}>
          <span
            onClick={(e) => {
              e.stopPropagation(); // Detiene la propagación del evento
              handleDelete(record);
            }}
            style={{ cursor: "pointer", fontSize: "16px" }}
          >
            <DeleteOutlined />
          </span>
        </div>
      ),
    },
  ];

  return (
    <AdminLayout>
      <Row justify="start" align="middle" style={{ marginBottom: "16px" }}>
        <Col span={6}>
          <h3 className="mb-8 text-sc-black font-bold text-[32px]">Cargas Masivas</h3>
        </Col>
        <Col span={18}>
          <Row justify="end" gutter={8}>
            <Col>
              <SCButton onClick={exportCSV}>Exportar a CSV</SCButton>
            </Col>
            <Col>
              <ImportCsvModal
                showModal={() => setStateModal(true)}
                hideModal={() => setStateModal(false)}
                stateModal={stateModal}
              />
            </Col>
            <Col>
              <SCButton onClick={() => setAddSubModal(true)}>Agregar Suscriptor</SCButton>
            </Col>
          </Row>
          <Row style={{ marginTop: "16px" }}>
            <Col span={8}>
              <Search
                placeholder="Buscar suscriptor..."
                allowClear
                onSearch={(value) => setSearchTerm(value)}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {loading ? (
        <div style={{ textAlign: "center", padding: "40px" }}>
          <Spin size="large" />
        </div>
      ) : (
        <Table
          rowKey={(record) => record.email}
          dataSource={usersData}
          columns={columns}
          pagination={paginationConfig}
          rowSelection={rowSelection}
          onRow={(record) => ({
            onClick: (event) => {
              if (event.target.nodeName !== "INPUT") {
                showUser(record.email);
              }
            },
          })}
        />
      )}
      <AddSubscriberModal
        visible={addSubModal}
        onCancel={() => setAddSubModal(false)}
        onSuccess={() => {
          setAddSubModal(false);
          fetchUsers();
        }}
      />
    </AdminLayout>
  );
};

export default Suscriptores;
