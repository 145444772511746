import React, { useState, useEffect } from "react";
import moment from "moment";
import AdminLayout from "../components/AdminLayout";
import { serverUri } from "../api/serverUri";
import {
  Table,
  Input,
  DatePicker,
  Checkbox,
  message,
  Tooltip,
  Row,
  Col,
} from "antd";
import {
  CaretDownOutlined,
  MinusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import SCButton from "../components/SCButton";
import { formatoFecha, downloadExcelFile } from "../components/helper/utilidades";
import { IoFilterSharp } from "@react-icons/all-files/io5/IoFilterSharp";
import { startOfDay, endOfDay, formatISO } from "date-fns";

const ReporteLives = () => {
  const [livesData, setLivesData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);

  // Fechas por defecto: inicio y fin del día actual.
  const defaultStart = moment().startOf("day");
  const defaultEnd = moment().endOf("day");

  // Filtros y parámetros para la consulta.
  const [filters, setFilters] = useState({
    startDate: defaultStart,
    endDate: defaultEnd,
    liveId: [],
    suscription: [],
    searchQuery: "",
    sortField: "",
    sortOrder: "",
    page: 1,
    pageSize: 10,
  });

  // Estados para mostrar/ocultar paneles de filtro.
  const [showSuscripcionFilter, setShowSuscripcionFilter] = useState(false);
  const [showFechasFilter, setShowFechasFilter] = useState(false);
  const [showLivesFilter, setShowLivesFilter] = useState(false);

  // Opciones para el filtro de suscripción.
  const optionsSuscripcion = [
    { label: "PREMIUM", value: "PREMIUM" },
    { label: "FREE", value: "FREE" },
    { label: "SOCIO", value: "SOCIO" },
  ];
  const [checkedSuscripcion, setCheckedSuscripcion] = useState([]);

  // Datos para el dropdown de Lives.
  const [livesOptions, setLivesOptions] = useState([]);
  const [checkedLive, setCheckedLive] = useState([]);

  // Estado que almacena los índices globales seleccionados (del listado completo).
  const [selectedGlobalKeys, setSelectedGlobalKeys] = useState([]);
  // Flag que indica si se ha hecho "Select All" a nivel global.
  const [selectAllAcrossPages, setSelectAllAcrossPages] = useState(false);

  // ---------------------------------------------------------------------
  // 1. Cargar lista de Lives para el filtro.
  // ---------------------------------------------------------------------
  const fetchLivesList = async () => {
    try {
      const resp = await fetch(`${serverUri}/lives`);
      if (!resp.ok) throw new Error("Error al obtener la lista de lives");
      const data = await resp.json();
      setLivesOptions(data);
    } catch (error) {
      console.error(error);
      message.error("Error al obtener la lista de lives");
    }
  };

  useEffect(() => {
    fetchLivesList();
  }, []);

  // ---------------------------------------------------------------------
  // 2. Consulta principal al endpoint /reportb2b-lives.
  // ---------------------------------------------------------------------
  const fetchReportLives = async () => {
    setLoading(true);
    try {
      const startD = filters.startDate || defaultStart;
      const endD = filters.endDate || defaultEnd;
      if (startD.isAfter(endD)) {
        message.warning("La fecha inicio no puede ser mayor que la fecha fin.");
        setFilters({ ...filters, startDate: defaultStart, endDate: defaultEnd });
        setLoading(false);
        return;
      }
      const payload = {
        startDate: formatISO(startOfDay(startD.toDate())),
        endDate: formatISO(endOfDay(endD.toDate())),
        liveId: filters.liveId,
        suscription: filters.suscription,
        searchQuery: filters.searchQuery,
        sortField: filters.sortField,
        sortOrder: filters.sortOrder,
        page: filters.page,
        pageSize: filters.pageSize,
      };
      const response = await fetch(`${serverUri}/reportb2b-lives`, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: { "Content-Type": "application/json" },
      });
      if (!response.ok) throw new Error("Error al cargar datos del reporte");
      const json = await response.json();
      // Calculamos el índice global para cada registro:
      // globalIndex = (currentPage - 1) * pageSize + (localIndex + 1)
      const dataConGlobalIndex = json.results.map((item, index) => ({
        ...item,
        globalIndex: (filters.page - 1) * filters.pageSize + (index + 1),
      }));
      setLivesData(dataConGlobalIndex);
      setTotalRecords(json.total);
    } catch (err) {
      message.error("Error al cargar los datos del reporte");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReportLives();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  // ---------------------------------------------------------------------
  // 3. Manejo de filtros y búsqueda.
  // ---------------------------------------------------------------------
  const handleSearch = (e) => {
    setFilters({ ...filters, searchQuery: e.target.value, page: 1 });
  };

  const handleDate = (value, isStart) => {
    if (!value) {
      setFilters({
        ...filters,
        [isStart ? "startDate" : "endDate"]: isStart ? defaultStart : defaultEnd,
        page: 1,
      });
      setShowFechasFilter(false);
      return;
    }
    const newDate = moment(value);
    if (isStart) {
      if (filters.endDate && newDate.isAfter(filters.endDate)) {
        message.warning("La fecha inicio no puede ser mayor que la fecha fin.");
        return;
      }
      setFilters({ ...filters, startDate: newDate, page: 1 });
    } else {
      if (filters.startDate && newDate.isBefore(filters.startDate)) {
        message.warning("La fecha fin no puede ser menor que la fecha inicio.");
        return;
      }
      setFilters({ ...filters, endDate: newDate, page: 1 });
    }
    setShowFechasFilter(false);
  };

  const handleCheckboxChange = (checkedValues) => {
    setCheckedSuscripcion(checkedValues);
    setFilters({ ...filters, suscription: checkedValues, page: 1 });
  };

  const handleLiveCheckboxChange = (checkedValues) => {
    setCheckedLive(checkedValues);
    setFilters({ ...filters, liveId: checkedValues, page: 1 });
  };

  const handleDataFilter = () => {
    setShowFechasFilter(false);
    setShowSuscripcionFilter(false);
    setShowLivesFilter(false);
    setFilters({ ...filters, page: 1 });
  };

  const handleClearFilter = () => {
    setCheckedSuscripcion([]);
    setCheckedLive([]);
    setShowFechasFilter(false);
    setShowSuscripcionFilter(false);
    setShowLivesFilter(false);
    setFilters({
      startDate: defaultStart,
      endDate: defaultEnd,
      liveId: [],
      suscription: [],
      searchQuery: "",
      sortField: "",
      sortOrder: "",
      page: 1,
      pageSize: 10,
    });
    setSelectedGlobalKeys([]);
    setSelectAllAcrossPages(false);
  };

  // ---------------------------------------------------------------------
  // 4. Exportar a Excel.
  // ---------------------------------------------------------------------
  const handleGenerateExcel = async () => {
    if (selectedGlobalKeys.length === 0) {
      message.error("No hay registros para exportar.");
      return;
    }
    let dataToExport = [];
    try {
      // Tomamos el mayor índice seleccionado para traer todos los registros hasta allí.
      const maxSelected = Math.max(...selectedGlobalKeys);
      const payload = {
        startDate: formatISO(startOfDay(filters.startDate.toDate())),
        endDate: formatISO(endOfDay(filters.endDate.toDate())),
        liveId: filters.liveId,
        suscription: filters.suscription,
        searchQuery: filters.searchQuery,
        sortField: filters.sortField,
        sortOrder: filters.sortOrder,
        page: 1,
        pageSize: maxSelected,
      };
      const response = await fetch(`${serverUri}/reportb2b-lives`, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: { "Content-Type": "application/json" },
      });
      if (!response.ok) throw new Error("Error al cargar datos del reporte");
      const json = await response.json();
      // Recalculamos el índice global para cada registro exportado.
      dataToExport = json.results.map((item, index) => ({
        ...item,
        globalIndex: index + 1,
      }));
      // Filtramos los registros que tengan un globalIndex en la selección global.
      dataToExport = dataToExport.filter((item) =>
        selectedGlobalKeys.includes(item.globalIndex)
      );
    } catch (err) {
      message.error("Error al cargar todos los datos para exportar");
      return;
    }
    if (dataToExport.length > 0) {
      downloadExcelFile(dataToExport, "ReporteLives");
    } else {
      message.error("No hay registros para exportar.");
    }
  };

  // ---------------------------------------------------------------------
  // 5. Configuración de la tabla y manejo de selección por índice global.
  // ---------------------------------------------------------------------
  // Calculamos los índices globales de las filas de la página actual.
  const currentPageGlobalKeys = livesData.map((row) => row.globalIndex);

  // Creamos un checkbox personalizado para el encabezado.
  const customSelectionColumnTitle = (
    <Checkbox
      indeterminate={
        selectedGlobalKeys.length > 0 &&
        selectedGlobalKeys.length < totalRecords
      }
      checked={selectedGlobalKeys.length === totalRecords && totalRecords > 0}
      onChange={(e) => {
        if (e.target.checked) {
          // Si se marca, se seleccionan TODOS los índices del 1 al totalRecords.
          const allGlobalKeys = Array.from({ length: totalRecords }, (_, i) => i + 1);
          setSelectedGlobalKeys(allGlobalKeys);
          setSelectAllAcrossPages(true);
        } else {
          // Si se desmarca, se limpia la selección.
          setSelectedGlobalKeys([]);
          setSelectAllAcrossPages(false);
        }
      }}
    />
  );

  const rowSelection = {
    columnTitle: customSelectionColumnTitle,
    // Para la página actual, se muestran como seleccionadas aquellas filas cuyo globalIndex esté en la selección global.
    selectedRowKeys: currentPageGlobalKeys.filter((key) =>
      selectedGlobalKeys.includes(key)
    ),
    onChange: (selectedRowKeysFromPage) => {
      // Si en la página actual se desmarca alguna fila mientras se tenía "select all", se desactiva ese flag.
      if (selectAllAcrossPages) {
        setSelectAllAcrossPages(false);
      }
      // Actualizamos la selección global: conservamos las filas de otras páginas y reemplazamos las de la actual.
      const newGlobalSelection = [
        ...selectedGlobalKeys.filter((key) => !currentPageGlobalKeys.includes(key)),
        ...selectedRowKeysFromPage,
      ];
      setSelectedGlobalKeys(newGlobalSelection);
    },
    preserveSelectedRowKeys: true,
  };

  const columns = [
    {
      title: "Suscripción",
      dataIndex: "suscripcion",
      key: "suscripcion",
      sorter: true,
    },
    {
      title: "Nombre del usuario",
      dataIndex: "nombre",
      key: "nombre",
    },
    {
      title: "Apellido del usuario",
      dataIndex: "apellido",
      key: "apellido",
    },
    {
      title: "Empresa",
      dataIndex: "empresa",
      key: "empresa",
    },
    {
      title: "Código",
      dataIndex: "codigo",
      key: "codigo",
    },
    {
      title: "Contenido Visto",
      dataIndex: "contenidoVisto",
      key: "contenidoVisto",
      sorter: true,
      render: (text) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Tiempo de uso",
      dataIndex: "tiempoDeUso",
      key: "tiempoDeUso",
      sorter: true,
      render: (decimalMinutes) => {
        if (!decimalMinutes) return "0 mnt 0 seg";
        const totalSeconds = Math.round(decimalMinutes * 60);
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return `${minutes} mnt ${seconds} seg`;
      },
    },
    {
      title: "Fecha Inicio",
      dataIndex: "fechaInicio",
      key: "fechaInicio",
      sorter: true,
      render: (text) => (text ? moment(text).format("DD/MM/YYYY HH:mm") : "-"),
    },
    {
      title: "Fecha Fin",
      dataIndex: "fechaFin",
      key: "fechaFin",
      sorter: true,
      render: (text) => (text ? moment(text).format("DD/MM/YYYY HH:mm") : "-"),
    },
  ];

  const handleTableChange = (pagination, tableFilters, sorter) => {
    setFilters({
      ...filters,
      page: pagination.current,
      pageSize: pagination.pageSize,
      sortField: sorter.field || "",
      sortOrder:
        sorter.order === "ascend"
          ? "asc"
          : sorter.order === "descend"
          ? "desc"
          : "",
    });
  };

  return (
    <AdminLayout>
      <Row justify="start">
        <Col span={12} sm={18} md={12} lg={8}>
          <h3 className="mb-8 text-sc-black font-bold text-[24px] text-left md:text-[32px]">
            Reporte de Lives
          </h3>
        </Col>
        <Col span={12} sm={6} md={12} lg={16}>
          <Row justify="end">
            <Col className="mr-2 mt-1">
              <SCButton className="text-sm rounded" onClick={handleGenerateExcel}>
                Exportar a Excel
              </SCButton>
            </Col>
            <Col className="mt-1">
              <SCButton className="text-sm rounded" onClick={handleClearFilter}>
                Reset Filtro
              </SCButton>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row justify="start" gutter={8}>
        <Col xs={12} sm={12} md={8} lg={7}>
          <div className="w-auto ml-2 mr-2">
            <Input
              placeholder="Buscar..."
              allowClear
              value={filters.searchQuery}
              onChange={handleSearch}
              style={{ borderRadius: "10px" }}
              prefix={<SearchOutlined />}
            />
          </div>
        </Col>
      </Row>

      <Row className="mt-2" gutter={16}>
        <Col xs={24} sm={24} md={14} lg={14}>
          <Row>
            {/* Filtro por Suscripción */}
            <Col span={8}>
              <div
                className="bg-gray-50 mt-2 ml-2 mr-2 cursor-pointer h-12 sm:h-11 md:h-12 xl:h-10 lg:h-14 w-auto shadow-lg border flex flex-row justify-between border-slate-400 rounded-xl"
                onClick={() => setShowSuscripcionFilter(!showSuscripcionFilter)}
              >
                <div className="ml-4 mt-1">
                  <p className="text-xs text-left text-[10px] sm:text-[12px] md:text-[14px]">
                    Suscripción:{" "}
                    <b className="md:text-[12px]">
                      {checkedSuscripcion.length > 0
                        ? checkedSuscripcion.join(", ")
                        : "Seleccionar"}
                    </b>
                  </p>
                </div>
                <div className="mr-4">
                  <CaretDownOutlined />
                </div>
              </div>
              {showSuscripcionFilter && (
                <div className="absolute z-40 -mt-4 mb-4 ml-2 mr-2 w-full">
                  <div className="flex flex-col justify-start mt-1 mr-4 border-b border-l border-r rounded-b-xl border-slate-400 bg-gray-50">
                    <div className="w-full border-t mt-2 border-slate-300">
                      <Checkbox.Group
                        onChange={handleCheckboxChange}
                        value={checkedSuscripcion}
                        className="flex flex-col justify-center w-full"
                      >
                        {optionsSuscripcion.map((item) => (
                          <Checkbox
                            key={item.value}
                            value={item.value}
                            className="w-10/12 px-4 justify-start hover:bg-sc-primary hover:opacity-90"
                          >
                            {item.label}
                          </Checkbox>
                        ))}
                      </Checkbox.Group>
                    </div>
                  </div>
                </div>
              )}
            </Col>

            {/* Filtro por Lives */}
            <Col span={8}>
              <div
                className="bg-gray-50 mt-2 ml-2 mr-2 cursor-pointer h-12 sm:h-11 md:h-12 xl:h-10 lg:h-14 w-auto shadow-lg border flex flex-row justify-between border-slate-400 rounded-xl"
                onClick={() => setShowLivesFilter(!showLivesFilter)}
              >
                <div className="ml-4 mt-1">
                  <p className="text-xs text-left text-[10px] sm:text-[12px] md:text-[14px]">
                    Live:{" "}
                    <b className="md:text-[12px] ml-1">
                      {checkedLive.length > 0
                        ? checkedLive
                            .map((id) => {
                              const liveObj = livesOptions.find((l) => l.id === id);
                              return liveObj ? liveObj.nombre : "Desconocido";
                            })
                            .join(", ")
                        : "Seleccionar"}
                    </b>
                  </p>
                </div>
                <div className="mr-4">
                  <CaretDownOutlined />
                </div>
              </div>
              {showLivesFilter && (
                <div className="absolute z-40 -mt-4 mb-4 ml-2 mr-2 w-full">
                  <div className="flex flex-col justify-start mt-1 mr-4 border-b border-l border-r rounded-b-xl border-slate-400 bg-gray-50">
                    <div className="w-full border-t mt-2 border-slate-300">
                      <Checkbox.Group
                        onChange={handleLiveCheckboxChange}
                        value={checkedLive}
                        className="flex flex-col justify-center w-full"
                      >
                        {livesOptions.map((live) => (
                          <Checkbox
                            key={live.id}
                            value={live.id}
                            className="w-10/12 px-4 justify-start hover:bg-sc-primary hover:opacity-90"
                          >
                            {live.nombre}
                          </Checkbox>
                        ))}
                      </Checkbox.Group>
                    </div>
                  </div>
                </div>
              )}
            </Col>

            {/* Filtro por Fechas */}
            <Col span={8}>
              <div
                className="bg-gray-50 mt-2 ml-2 mr-2 cursor-pointer h-12 sm:h-11 md:h-12 xl:h-10 lg:h-14 w-auto shadow-lg border flex flex-row justify-between border-slate-400 rounded-xl"
                onClick={() => setShowFechasFilter(!showFechasFilter)}
              >
                <div className="ml-4 mt-1">
                  <p className="text-xs text-left text-[10px] sm:text-[12px] md:text-[14px]">
                    Fechas:
                  </p>
                  <p className="text-xs text-left -mt-3 mb-1 text-[10px] sm:text-[12px] md:text-[12px]">
                    <b className={showFechasFilter ? "opacity-0" : ""}>
                      {filters.startDate && filters.endDate
                        ? `${filters.startDate.format("DD/MM/YYYY")} - ${filters.endDate.format("DD/MM/YYYY")}`
                        : "Seleccionar"}
                    </b>
                  </p>
                </div>
                <div className="mr-4">
                  <CaretDownOutlined />
                </div>
              </div>
              {showFechasFilter && (
                <div className="absolute z-40 -mt-4 mb-4 ml-2 mr-2 w-full">
                  <div className="mr-4 border-b border-l border-r -mt-2 border-gray-400 rounded-b-xl bg-gray-50">
                    <p className="text-xs text-left mt-1 ml-4 mb-0 md:-mt-4 lg:-mt-4 text-[10px] sm:text-[12px] md:text-[12px]">
                      <b className="md:text-[12px]">
                        {filters.startDate && filters.endDate
                          ? `${formatoFecha(filters.startDate).replaceAll("-", "/")} - ${formatoFecha(filters.endDate).replaceAll("-", "/")}`
                          : "Seleccionar"}
                      </b>
                    </p>
                    <div className="flex justify-around border-t mt-0 pt-2 border-slate-300">
                      <div className="w-1/3">
                        <p className="text-left text-[10px] sm:text-[12px] md:text-[14px]">Desde:</p>
                      </div>
                      <div className="w-1/3">
                        <p className="text-left text-[10px] sm:text-[12px] md:text-[14px]">Hasta:</p>
                      </div>
                    </div>
                    <div>
                      <div className="flex justify-around p-2 -mt-4 mb-2">
                        <div>
                          <DatePicker
                            value={filters.startDate}
                            onChange={(value) => handleDate(value, true)}
                            format="DD/MM/YYYY"
                            placeholder="Seleccionar"
                            style={{ borderRadius: "10px", backgroundColor: "#DADADA" }}
                            suffixIcon={""}
                          />
                        </div>
                        <div>
                          <MinusOutlined />
                        </div>
                        <div>
                          <DatePicker
                            value={filters.endDate}
                            onChange={(value) => handleDate(value, false)}
                            format="DD/MM/YYYY"
                            placeholder="Seleccionar"
                            style={{ borderRadius: "10px", backgroundColor: "#DADADA" }}
                            suffixIcon={""}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={10} lg={10}>
          <div className="flex w-full content-center">
            <div
              className="bg-gray-50 border shadow-lg border-slate-300 h-10 cursor-pointer ml-4 mt-2 w-auto rounded-xl flex justify-around"
              onClick={handleDataFilter}
            >
              <div className="mt-2 ml-2 md:ml-5 lg:ml-2">
                <IoFilterSharp size={18} />
              </div>
              <div className="mt-2 ml-2 mr-8 text-sm text-[10px] sm:text-[12px] md:text-[14px]">
                <p>Filtrar</p>
              </div>
            </div>
            <div
              className="bg-gray-50 border shadow-lg border-slate-300 h-10 cursor-pointer ml-2 mt-2 w-auto rounded-xl flex justify-around"
              onClick={handleClearFilter}
            >
              <div className="mt-2 ml-2 lg:ml-4 mr-8 text-xs text-[10px] sm:text-[12px] md:text-[14px]">
                <p>Reset Filtro</p>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      {/* Tabla de resultados */}
      <Table
        className="mt-4 cursor-pointer"
        rowSelection={rowSelection}
        dataSource={livesData}
        columns={columns}
        loading={loading}
        rowKey={(record) => record.globalIndex}
        scroll={{ x: 1300 }}
        pagination={{
          current: filters.page,
          pageSize: filters.pageSize,
          total: totalRecords,
          showSizeChanger: true,
        }}
        onChange={handleTableChange}
        size="small"
      />
    </AdminLayout>
  );
};

export default ReporteLives;
