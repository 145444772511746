import React from "react";
import { Router } from "@reach/router";
import PrivateRoute from "../components/privateRoute";
import Profile from "../views/Profile";
import Login from "../views/Login";
import Suscriptores from "../views/Suscriptores";
import ReporteCuestionarios from "../views/ReporteCuestionarios";
import ReporteSuscriptores from "../views/ReporteSuscriptores";
import ReporteUso from "../views/ReporteUso";
// import [id] from "../views/[id]";
import Onboardings from "../views/Onboardings";
import Cuestionario from "../views/Cuestionario";
import ReporteCalificaciones from "../views/ReporteCalificaciones";
import { ProgramarNotificaciones } from "../views/ProgramarNotificaciones";
import { AlertasPromocionales } from "../views/AlertasPromocionales";
import { CarrouselBanners } from "../views/CarrouselBanner";
import ReporteLives from "../views/ReporteLives";


const App = () => (
  <div className="h-full">
    <Router>
      <PrivateRoute path="/app/suscriptores" component={Suscriptores} />
      <PrivateRoute path="/app/profile" component={Profile} />
      <PrivateRoute
        path="/app/reporte-suscriptores"
        component={ReporteSuscriptores}
      />
      <PrivateRoute
        path="/app/reporte-cuestionarios"
        component={ReporteCuestionarios}
      />
      <PrivateRoute path="/app/reporte-de-uso" component={ReporteUso} />
      <PrivateRoute path="/app/reporte-de-lives" component={ReporteLives} />

      <PrivateRoute
        path="/app/reporte-de-calificaciones"
        component={ReporteCalificaciones}
      />
      <PrivateRoute path="/app/onboardings" component={Onboardings} />
      <PrivateRoute path="/app/cuestionario" component={Cuestionario} />
      <PrivateRoute
        path="/app/programar-notificaciones"
        component={ProgramarNotificaciones}
      />
      <PrivateRoute
        path="/app/alertas-promocionales"
        component={AlertasPromocionales}
      />
      <PrivateRoute
        path="/app/banners-carrousel"
        component={CarrouselBanners}
      />
      

      {/* <PrivateRoute path="/app/profile" component={Profile} /> */}
      <Login path="/app/login" />
    </Router>
  </div>
);
export default App;
