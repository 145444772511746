import React from "react";
import { Modal, Form, Input, Divider } from "antd";
import SCButton from "./SCButton";
import Swal from "sweetalert2";
import { addSubscriber } from "../api/users"; // Llama al endpoint /import/createSubscriber

const AddSubscriberModal = ({ visible, onCancel, onSuccess }) => {
  const [form] = Form.useForm();

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        // Llama a la API para agregar el suscriptor
        addSubscriber(values)
          .then(() => {
            Swal.fire("Éxito", "Suscriptor agregado correctamente", "success");
            form.resetFields();
            onSuccess();
          })
          .catch((error) => {
            console.error("Error al agregar suscriptor:", error);
            Swal.fire("Error", "No se pudo agregar el suscriptor", "error");
          });
      })
      .catch((info) => {
        console.log("Validation Failed:", info);
        Swal.fire(
          "Error",
          "Por favor, corrige los errores en el formulario",
          "error"
        );
      });
  };

  return (
    <Modal
      title="Agregar Suscriptor"
      visible={visible}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      footer={[
        <div className="mb-2">
          <SCButton
            key="cancel"
            onClick={() => {
              form.resetFields();
              onCancel();
            }}
          >
            Cancelar
          </SCButton>
        </div>,
        <div>
          <SCButton key="submit" type="primary" onClick={handleSubmit}>
            Agregar
          </SCButton>
        </div>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Nombre"
          name="nombre"
          rules={[{ required: true, message: "El nombre es obligatorio" }]}
        >
          <Input placeholder="Nombre" />
        </Form.Item>
        <Form.Item
          label="Apellido"
          name="apellido"
          rules={[{ required: true, message: "El apellido es obligatorio" }]}
        >
          <Input placeholder="Apellido" />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "El email es obligatorio" },
            { type: "email", message: "Ingresa un email válido" },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          label="Contraseña"
          name="password"
          rules={[{ required: true, message: "La contraseña es obligatoria" }]}
        >
          <Input.Password placeholder="Contraseña" />
        </Form.Item>
        <Form.Item label="Empresa" name="empresa">
          <Input placeholder="Empresa" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddSubscriberModal;
