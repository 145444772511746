import React, { useState, useRef } from "react";
import { Modal, message, Typography } from "antd";
import axios from "axios";
import SCButton from "../components/SCButton";
import { navigate } from "gatsby";
import { serverUri } from "../api/serverUri";
import Swal from "sweetalert2";

const { Text } = Typography;

const ImportCsvModal = ({ showModal, hideModal, stateModal }) => {
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [controller, setController] = useState(null);
  const fileInputRef = useRef(null);

  const handleChange = (event) => {
    setError("");
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  // Función para realizar la validación del archivo (validateOnly=true)
  const handleValidate = async () => {
    if (!file) {
      setError("No se seleccionó ningún archivo.");
      return;
    }
    // Preparar FormData y abort controller
    if (controller) controller.abort();
    const newController = new AbortController();
    setController(newController);
    setIsLoading(true);

    const data = new FormData();
    data.append("files", file);

    try {
      const response = await axios.post(`${serverUri}/import/users?validateOnly=true`, data, {
        signal: newController.signal,
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.status === 200) {
        // Opcional: Puedes mostrar un preview de la validación aquí usando response.data.preview
        const confirmResult = await Swal.fire({
          title: "Validación exitosa",
          text: "El archivo ha sido validado. ¿Desea proceder con la carga definitiva?",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Sí, cargar",
          cancelButtonText: "Cancelar",
        });
        if (confirmResult.isConfirmed) {
          await handleUpload(); // Realiza la carga definitiva
        }
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("La validación fue cancelada");
      } else if (error.response && error.response.status === 400) {
        setError(error.response.data.message || "El archivo está mal construido.");
      } else {
        console.error("Error en la validación:", error);
        setError("Error al validar el archivo.");
      }
    } finally {
      setIsLoading(false);
      // No reseteamos el file acá para permitir reintentos
      setController(null);
    }
  };

  // Función para hacer la carga definitiva (sin validateOnly)
  const handleUpload = async () => {
    if (!file) {
      setError("No se seleccionó ningún archivo.");
      return;
    }
    if (controller) controller.abort();
    const newController = new AbortController();
    setController(newController);
    setIsLoading(true);

    const data = new FormData();
    data.append("files", file);

    try {
      const response = await axios.post(`${serverUri}/import/users`, data, {
        signal: newController.signal,
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.status === 200) {
        message.success("Archivo cargado con éxito");
        hideModal();
        // Navegamos a la bitácora con el id retornado por el servidor
        navigate(`/suscriptores/bitacora/${response.data.idBitacora}`);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("La carga fue cancelada");
      } else if (error.response && error.response.status === 400) {
        setError(error.response.data.message || "El archivo está mal construido.");
      } else {
        console.error("Error en la carga:", error);
        setError("Error al cargar el archivo.");
      }
    } finally {
      setIsLoading(false);
      setFile(null);
      if (fileInputRef.current) fileInputRef.current.value = null;
      setController(null);
    }
  };

  const handleCancel = () => {
    if (controller) {
      controller.abort();
      setController(null);
    }
    if (fileInputRef.current) fileInputRef.current.value = null;
    setFile(null);
    setError("");
    hideModal();
  };

  return (
    <>
      <SCButton type="primary" onClick={showModal}>
        Importar desde CSV
      </SCButton>
      <Modal
        title="Importar usuarios desde Archivo CSV"
        visible={stateModal}
        onOk={handleValidate}
        onCancel={handleCancel}
        okText="Validar archivo"
        cancelText="Cancelar carga"
        confirmLoading={isLoading}
      >
        <div>
          <input type="file" accept=".csv" onChange={handleChange} ref={fileInputRef} />
        </div>
        {error && (
          <div style={{ marginTop: "10px" }}>
            <Text type="danger">{error}</Text>
          </div>
        )}
      </Modal>
    </>
  );
};

export default ImportCsvModal;
